// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
@import "fancybox/jquery.fancybox.scss";
@import "connectiv/z-index.scss";
//@import "connectiv/sprite.scss";
//@import "connectiv/custom-font.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";

// Font-Awesome
@import "font-awesome/font-awesome.scss";

//Slick Slider
//@import "slick/slick.scss";
//@import "slick/slick-theme.scss";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

//Sonstiges
@import "connectiv/custom-mobile-nav.scss";

/* ==========================================================================
   Project's custom styles
   ========================================================================== */

#main-nav .fa-facebook-official {
  font-size: 26px;
  margin-top: -3px;
}

#main-nav.start .fa-facebook-official {
  margin-top: -2px;
}

#main-nav .fa-facebook-official:hover {
  text-decoration: none;
}

.btn {
  line-height: 1.42857143; //fixes SASS number precision issue
}

$brand-height: 52px; //Höhe der Logografik
$brand-padding-vertical: (($navbar-height - $brand-height) / 2);

.navbar-brand {
  padding: $brand-padding-vertical 10px;
}

.container-fluid {
  max-width: 1000px + $grid-gutter-width;
}

.top-bar {
  font-size: 12px;
  //padding-top: 6px;
  font-family: 'Open Sans', sans-serif;
}

.logo-outer {
  position: relative;
  z-index: 500;
}

.logo {
  width: 100%;
  display: block;
  padding-right: 10px;
  padding-left: 10px;
  > img {
    margin: 10px auto;
    display: block;
  }
}

.logo-top {
  display: none;
}

.logo-bottom {
  display: none;
}

.nav-global {
  list-style: none;
  padding: 0;
}

.label-pathfinder {
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-top: 6px;
  }

.pathfinder a {
  color: #fff;
}

.pathfinder {
  list-style: none;
  padding: 0;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 6px;
}

.pathfinder li {
  display: inline-block;
  float: left;
}

.pathfinder li:not(:last-child) {
  &:after {
    content: '/';
    margin-left: 5px;
    margin-right: 5px;
  }
}

.pathfinder .active {
  text-decoration: underline;
}

.nav-global {
  float: right;
  margin-top: 6px;
  margin-bottom: 6px;
}

.nav-global li {
  display: inline-block;
}

.nav-global li:not(:last-child) a {
  margin-right: 10px;
}

.themepic {
  position: relative;
}

.themepic p {
  margin: 0;
}

.themepic-col p img {
  width: 100%;
}

.themepic.folge {
  background-image: url(../img/blurred_header_unterseite.jpg);
  background-repeat: no-repeat;
  overflow: hidden;
}

.themepic-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
}

.themepic > p > img {
  display: block;
  width: 100%;
  height: auto;
}

#main-nav {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  padding-left: 0;
}

h1, h2, h3, h4 {
  margin-top: 0;
}

h1, h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
  color: $blue-text;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  color: $blue-text;
}

#content, #tinymce {
  font-family: 'Open Sans', sans-serif;
  color: #4e4e4e;
  font-size: 13px;
  margin: 0 0 60px;
  line-height: 20px;

  p {
    margin-bottom: 20px;
  }
}
#content .btn {
  white-space: normal;
}

.nav-left {
  margin-top: 20px;
}

.teaser-row h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: $blue-text;
  bottom: 30px;
  left: 100px;
  position: absolute;
}

.start-teaser {
  display: block;
  overflow: hidden;
  position: relative;
}

.start-teaser-overlay {
  position: absolute;
  bottom: 0;
}

.start-teaser-icon {
  width: 55px;
  z-index: 1000;
  position: absolute;
  bottom: 26px;
  left: 25px;
}

.promotion {
  background-image: url('../img/blurred_image.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 50px;
  background-size: cover;
  box-shadow: inset 0px 0px 5px 0px rgba(92, 91, 91, 0.4);
}

.promotion-overlay {
  position: relative;
  top: -48px;
  z-index: 200;
}

.promotion-overlay--map {
  top: 0;
}

.promotion__bg-map {
  background-image: url("../img/standortkarte.png");
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.promotion-text {
  background-color: rgba(255,255,255,0.6);
  padding: 40px 38px 38px 38px;
}

.promotion-text--map {
  margin-top: -44px!important;
}

.promotion-text h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 15px;
  color: $blue-text;
}

.promotion-text p {
  font-family: 'Open Sans', sans-serif;
  color: #4e4e4e;
  font-size: 14px;
  margin: 0;
  line-height: 20px;
}

.promotion-text .button-blue {
  z-index: 300;
  position: relative;
}

.button-blue {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  background-color: $brand-primary;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  //line-height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover, &:focus, &:active {
    color: #fff;
    text-decoration: none;
    background-color: darken($brand-primary, 5%);
  }
}

.button-big {
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 50px;
}

.button-small {
  padding-top: 8px;
  padding-bottom: 8px;
}

.teaser-bottom-row .button-small {
  left: 35px;
  position: absolute;
  bottom: 25px;
  padding-right: 45px;
}

.col-sm-12 .button-small {
  padding-right: 20px;
}

.money {
  padding-right: 50px;
}

.money:after {
  content: '';
  position: absolute;
  background-image: url('../img/money@2x.png');
  background-repeat: no-repeat;
  display: block;
  width: 80px;
  height: 80px;
  background-size: contain;
  right: -45px;
  top: -20px;
}

.budget {
  padding-right: 50px;
}

.budget:after {
  content: '';
  position: absolute;
  background-image: url('../img/taschenrechner.png');
  background-repeat: no-repeat;
  display: block;
  width: 80px;
  height: 80px;
  background-size: contain;
  right: -45px;
  top: -20px;
}

.teaser-bottom {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 200px;
  padding-top: 20px;
}

.teaser-bottom-overlay {
  background-color: rgba(255,255,255,0.86);
  display: inline-block;
  padding-right: 30px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 340px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: $blue-text;
  line-height: 20px;
}

footer {
  background-color: $footer-bg;
  padding-top: 40px;
  padding-bottom: 20px;
}

.mobile-adjust {
  margin-bottom: 20px;
}

footer p {
  margin: 0;
}

.footer-nav {
  list-style: none;
  padding: 0;
}

h3 {
  color: $grey-text;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 20px;
}

.footer-nav a,
footer p {
  color: $grey-text;
  font-family: 'Open Sans', sans-serif;
  line-height: 23px;
  font-size: 12px;
}

.shk-logo {
  margin-bottom: 20px;
  max-width: 110px;
}

.references {
  margin-bottom: 40px;
}

.reference-item {
  display: block;
  margin-bottom: 20px;
  position: relative;
  max-width: 235px;
  margin-left: auto;
  margin-right: auto;
}

.reference-item-image {
  display: block;
  height: 150px;
  overflow: hidden;
}

.reference-item:hover,
.reference-item:focus,
.reference-item:active {
  text-decoration: none;
}

.reference-item-img {
  max-width: 100%;
}

.reference-item-desc {
  display: block;
  background-color: $light-grey;
  padding: 20px 20px 80px 20px;
  color: $grey-text;
  border-bottom: 1px solid #ccc;
}

.reference-item-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.reference-item-location {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  display: block;
  margin-bottom: 20px;
}

.reference-item-text {
  font-size: 13px!important;
  display: block;
  font-family: 'Open Sans', sans-serif;
}

.reference-item-icons {
  display: block;
  position: absolute;
  bottom: 25px;
  left: 0;
  text-align: center;
  width: 100%;
}

.reference-item-icons img {
  width: 35px;
}
.reference-item-icons img:not(:last-child) {
  margin-right: 12px;
}

.mosaic {
  img {
    float: left;
    margin-right: 5%;
    max-width: 35%!important;
  }
  .overlay {
    font-size: 16px;
    float: left;
    max-width: 60%;
    font-size: 12px;
  }
  .overlay a {
    color: #000;
  }
  >div {
    margin-bottom: 20px;
  }
  .team-description {
    line-height: 18px;
  }
}
.mosaic__fa {
  margin-right: 10px;
}

.mosaic-item {
  position: relative;
}

.mosaic-item .overlay span {
  display: block;
}

#content #et-opt-out {
  background-color: #0b66b0;
}

#content #et-opt-out.et-disabled {
  background-color: #ccc;
}

.cc-compliance a:hover {
  text-decoration: none;
}

@media (max-width: 375px) {
  .promotion-overlay-img {
    width: 100%;
  }
}

@media (max-width: $screen-xs-max) {
  .themepic.folge {
    background-image: none!important;
  }
  .themepic-col {
    padding-right: 0;
    padding-left: 0;
  }
  .nav-row {
    margin-left: 0;
    margin-right: 0;
  }
  .nav-col {
    padding-left: 0;
    padding-right: 0;
  }
  .nav-col #main-nav {
    padding-left: 0;
  }
  #content {
    margin-top: 20px;
  }
  .teaser-row {
    margin-bottom: 70px;
  }
  .start-teaser {
    margin: 0 auto 20px auto;
    max-width: 320px;
  }
  .promotion-overlay {
    margin-left: -10px;
    margin-right: -10px;
    overflow-x: hidden;
  }
  .promotion-overlay-img {
    margin: 0 auto;
    position: relative;
    display: block;
  }
  .promotion-text {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -84px;
    padding-top: 80px;
  }
  .teaser-bottom {
    margin-bottom: 40px;
  }
  .footer-nav {
    height: 150px;
  }
}

@media (min-width: $screen-xs) {
  .promotion__bg-map {
    height: 350px;
  }
}

/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  .top-bar {
    height: 30px;
    background-color: $global-nav-bg;
    color: #fff;
  }
  .logo {
    position: absolute;
    background-color: rgba(238,238,238,0.86);
  }
  .nav-global a {
    color: $light-grey-text;
  }
  .logo {
    > img {
      margin: 30px auto 20px auto;
    }
  }
  .logo.folge {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 11px;
    > img {
      margin-top: 59px;
    }
  }
  .themepic-col  {
    padding-right: 0;
    padding-left: 0;
  }
  .themepic-col img {
    margin-left: -10px;
  }
  .nav-outer {
    background-color: $brand-primary;
    margin-bottom: 40px;
  }
  #main-nav.start {
    padding: 21px 0 0 10px;
  }

  #main-nav {
    padding: 21px 0 0 0;
    height: 60px!important;
    font-size: 15px;
  }
  #main-nav > ul {
    list-style: none;
    padding: 0;
  }
  #main-nav > ul > li {
    display: inline-block;
    float: left;
  }
  #main-nav > ul > li:not(:last-child) {
    border-right: 1px solid #4999cc;
  }
  #main-nav > ul > li > a {
    color: #fff;
    margin-right: 12px;
  }
  #main-nav > ul > li:not(:first-child) > a {
    margin-left: 12px;
  }
  #main-nav.folge {
    font-size: 13px;
  }
  #main-nav.folge > ul > li > a {
    margin-right: 5px;
  }
  #main-nav.folge > ul > li:not(:first-child) > a {
    margin-left: 5px;
  }
  .teaser-row {
    margin-bottom: -25px;
  }

  .promotion {
    height: 210px;
    margin-bottom: 120px;
  }

  .promotion--map {
    height: 250px;
  }

  .promotion-overlay-img {
    width: 100%;
  }

  .promotion-text {
    height: 310px;
    padding: 20px;
  }

  .promotion-text p {
    font-size: 12px;
  }

  .promotion-text--map {
    margin-top: 0!important;
  }

  .promotion__bg-map {
    max-height: 250px;
  }

  .p-r-0 {
    padding-right: 0!important;
  }

  .p-l-0 {
    padding-left: 0!important;
  }

  .button-blue {
    font-size: 12px;
    padding-left: 11px;
    padding-right: 11px;
  }

  .button-big {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 38px;
  }

  .teaser-bottom-row {
    margin-bottom: 80px;
  }

  .teaser-bottom-overlay {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .teaser-bottom-row .button-small {
    bottom: 35px;
  }

  .shk-logo {
    float: right;
    margin-bottom: 0;
  }
  .nav-wave-top {
    margin-top: -10px;
  }
  .nav-left {
    margin-top: 0;
    padding: 20px;
    background-color: #F7F7F7;
  }
  .nav-left ul {
    list-style: none;
    font-family: 'Open Sans', sans-serif;
  }
  .nav-left a {
    display: block;
    color: $grey-text;
    &:hover {
      text-decoration: none;
      color: $blue-text;
    }
    &.active {
      color: $blue-text;
    }
  }
  .nav-left li.active a,
  .nav-left li.activesub > a {
    color: $blue-text;
  }
  .nav0-1 {
    padding: 0;
  }
  .nav0-1 > li > a {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .nav0-1__header {
    color: $blue-text;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
    display: block;
  }

  .nav0-2 {
    padding-left: 10px;
  }
  .nav0-2 > li > a {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .nav0-3 {
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .nav0-3 > li > a {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .reference-item-desc {
    height: 301px;
  }
  .mosaic {

    img {
      float: none;
      max-width: 100% !important;
      width: 100%;
    }

    .overlay {
      width: 100%;
      max-width: 100%;
      display: block;
      background: #0b66b0;
      -moz-transition: opacity 0.25s linear;
      -o-transition: opacity 0.25s linear;
      -webkit-transition: opacity 0.25s linear;
      transition: top 0.3s linear 0.1s;
      color: #fff;
      padding: 10px 15px;
      font-size: 13px;
      position: absolute!important;
      top: 202px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      a{
        color: #fff;
      }

      >.phone-small, .mail-small {
        display: inline-block;
        margin-right: 5px;
      }
    }

    /*
    >div {
      .overlay:hover,
      .overlay:focus,
      .overlay:active {
        opacity: 0.95;
      }
      img:hover {
        + .overlay {
          opacity: 0.95;
        }
      }
    }
    */
  }

  .mosaic__img {
    height: 202px;
    overflow: hidden;
  }

  /*
    .mosaic-item:focus .overlay,
    .mosaic-item:active .overlay {
      opacity: 0.95;
    }
  */
  .mosaic-item {
    /*overflow: hidden;*/
    height: 258px;
  }

  /*.mosaic-item img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  }*/

  .mosaic-item .overlay {
    top: 202px;
  }

  .mosaic-item__hov:hover .overlay,
  .mosaic-item__hov:focus .overlay {
    top: 0px;
  }

  .mobile-adjust {
    margin-bottom: 0;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {
  .logo.folge {
    > img {
      margin-top: 55px;
    }
  }
  #main-nav {
    padding: 21px 0 0 10px;
  }
  #main-nav > ul > li > a {
    margin-right: 27px;
  }
  #main-nav > ul > li:not(:first-child) > a {
    margin-left: 30px;
  }
  #main-nav.folge {
    font-size: 14px;
  }
  #main-nav.folge > ul > li > a {
    margin-right: 15px;
  }
  #main-nav.folge > ul > li:not(:first-child) > a {
    margin-left: 15px;
  }

  .promotion {
    height: 310px;
  }

  .promotion__bg-map {
    max-height: 310px;
  }

  .promotion-overlay-img {
    width: initial;
  }

  .promotion-text {
    padding: 60px 20px 20px 20px;
  }

  .promotion-text p {
    font-size: 14px;
  }

  .button-blue {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .button-big {
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 50px;
  }

  .nav-left {
    padding: 30px;
  }

  .themepic.folge {
    height: 155px;
  }

  .mosaic {

    .overlay {
      font-size: 12px;
      padding: 10px;
    }
  }

  .mosaic-item {
    height: 247px;
  }

  .mosaic-item .overlay {
    top: 188px;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {
  .logo.folge {
    > img {
      margin-top: 47px;
    }
  }
  .logo-top.folge {
    //top: 168px;
    top: 185px;
  }
  #main-nav.folge > ul > li > a {
    margin-right: 15px;
  }
  #main-nav.folge > ul > li:not(:first-child) > a {
    margin-left: 15px;
  }

  .mosaic-item {
    height: 260px;
  }

  .mosaic-item .overlay {
    top: 201px;
  }
}

@media (min-width: 1400px) {
  .logo-top {
    position: absolute;
    top: 193px;
    max-width: 100%;
    display: block;
  }
  .logo-bottom {
    position: absolute;
    bottom: 0;
    display: block;
  }

}